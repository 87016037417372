import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const WorkflowDetails = () => {
  return (
    <LayoutComponent>
      <div>
        <h4>Workflow Details</h4>
        <p>
          This outlines two key workflows in a project that impact how test
          cases are reviewed, approved, and assigned within projects.
        </p>

        <h5>1. Test Case Review Workflow</h5>
        <p>
          This workflow determines whether test cases require approval before
          being executed. The workflow can be configured as follows:
        </p>
        <ul>
          <li>
            <strong>If enabled:</strong>
            <ul>
              <li>
                Test cases must be approved by a reviewer before being added to
                a test cycle or executed.
              </li>
              <li>
                Project team members must assign a reviewer to the test cases
                they create. These reviewers can be:
                <ul>
                  <li>Project admins</li>
                  <li>Organization admins</li>
                  <li>
                    Other team members if the test case is assigned to them
                  </li>
                </ul>
              </li>
              <li>
                Assigned test cases awaiting approval will appear on the home
                page of the reviewer under <strong>Assigned for Review</strong>{" "}
                tab.
              </li>
              <li>
                Only approved test cases can be added to test cycles and
                executed.
              </li>
            </ul>
          </li>
          <li>
            <strong>If disabled:</strong>
            <ul>
              <li>
                Any user can set a test case's status as "Approved" without
                requiring a formal review process.
              </li>
              <li>
                Approved test cases can be added to test cycles and executed
                immediately.
              </li>
            </ul>
          </li>
        </ul>

        <h5>2. Mandatory Assignment of Test Cases</h5>
        <p>
          This workflow controls how test cases are assigned to users for
          execution. The workflow can be configured as follows:
        </p>
        <ul>
          <li>
            <strong>If enabled:</strong>
            <ul>
              <li>
                Test cases added to a test cycle must be assigned to a user for
                execution.
              </li>
              <li>Only the assigned user can execute the test cases.</li>
              <li>
                Test cases assigned for execution will appear on the user's home
                page under <strong>Assigned for Execution</strong> tab..
              </li>
            </ul>
          </li>
          <li>
            <strong>If disabled:</strong>
            <ul>
              <li>
                A default user will automatically be assigned to any test cases
                added to a test cycle.
              </li>
              <li>
                These assignments can be updated later from the{" "}
                <strong>Test Cases</strong> tab within the test cycle.
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </LayoutComponent>
  )
}

export default WorkflowDetails
